
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

import { Auth } from "@/api";

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class ForgotPasswordPage extends Vue {
  email = "";
  message = "";
  async resetPassword(): Promise<void> {
    try {
      const response = await Auth.forgotPassword(this.email);
      this.message = response.message;
    } catch ({ errors }) {
      (this.$refs.forgotPasswordForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(errors);
      await this.$error(errors);
    }
  }
}
